import { Ability } from '@casl/ability';
import { initialAbility } from './initialAbility';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const testUser = {
  id: 1,
  fullName: 'John Doe',
  username: 'johndoe',
  avatar: '/img/13-small.d796bffd.png',
  email: 'admin@demo.com',
  ability: [
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  // more...
};
const userData = JSON.parse(localStorage.getItem('userData')) || testUser;
const existingAbility = userData ? userData.ability : null;

export default new Ability(existingAbility || initialAbility);
